import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
    Backdrop,
    Box,
    Button,
    Fab,
    FabOwnProps,
    FormControlLabel,
    FormGroup,
    Grid,
    Grow,
    Hidden,
    Paper,
    Switch,
    useMediaQuery,
    useTheme,
    Zoom,
} from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { useTranslation } from 'react-i18next';

import { useCheckboxInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import { deviceService } from 'services/device-management/device.service';
import { accessPermissionsService, AccessPermissionTable } from 'services/device-management/accessPermissions.service';
import { AccessPermissionCreateRequest, AccessPermissionDeleteRequest, DeviceGetAllToTable, DeviceGroupGetAllToTable, DeviceOnlyIdRequest } from 'services/device-management/types';
import PeoplesTable, { PermissionChipType } from './PeoplesTable';
import ManageAccessDeviceTable from './ManageAccessDeviceTable';
import { useSnackbar, VariantType } from 'notistack';
import { getCreateErrorText, getDeleteErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { deviceGroupService } from 'services/device-management/deviceGroup.service';

interface FaqProp {
    icon: JSX.Element;
    label: string;
    labelAlwaysVisible: boolean;
    color: FabOwnProps['color'];
    onClick: () => void;
}
    
export default function ManageAccessTablesContainer() {
    const [sessionContext] = useSessionContext();
    const theme = useTheme();
    const [selected, setSelected] = React.useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = React.useState<string[]>([]);
    const [rows, setRows] = React.useState<DeviceGetAllToTable[] | DeviceGroupGetAllToTable[]>([]);
    const [peopleRows, setPeopleRows] = React.useState<AccessPermissionTable[]>([]);
    const [deviceGroupsChecked, setDeviceGroupsChecked] = React.useState(false);
    const [selectedDateStart, setSelectedDateStart] = React.useState<Date | null>(new Date());
    const [selectedDateEnd, setSelectedDateEnd] = React.useState<Date | null>(new Date());
    const { value: limitedTimeChecked, bind: bindLimitedTimeChecked, setValue: setLimitedTimeChecked } = useCheckboxInput(false);
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [ visibleDatePicker, setVisibleDatePicker ] = React.useState<boolean>(false);
    const [ locationId, setLocationId ] = React.useState<string>("");
    const [ departmentId, setDepartmentId ] = React.useState<string>("");
    const { enqueueSnackbar } = useSnackbar();

    const [t] = useTranslation();

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant, preventDuplicate: true });
    };

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const fabs: FaqProp[] = [
        {
            icon: <FastRewindIcon />,
            label: "Add permissions",
            labelAlwaysVisible: true,
            color: "success",
            onClick: () => {
                if (visibleDatePicker) handleAddClick()
                else setVisibleDatePicker(true);
            },
        },
        {
            icon: <FastForwardIcon />,
            label: "Remove permissions",
            labelAlwaysVisible: false,
            color: "warning",
            onClick: () => handleRemoveClick(),
        },
    ]

    useEffect(() => {
        fetchEmployeesWithAccess();
        fetchRows(deviceGroupsChecked);
        resetStates();
    }, [locationId, departmentId]);

    const fetchEmployeesWithAccess = () => {
        accessPermissionsService.getAllToTable(locationId, departmentId, sessionContext.token)
            .then(res => setPeopleRows(res))
            .catch(err => handleSnackBar(getGetErrorText(err), "error"));
    }

    const fetchRows = async (ifFetchDeviceGroup: boolean = false) => {
        if (ifFetchDeviceGroup) {

            deviceGroupService.getAllToTable(sessionContext.token)
                .then(res => setRows(res))
                .catch(err => handleSnackBar(getGetErrorText(err), "error"));

        } else {

            deviceService.getAllToTable(sessionContext.token)
                .then(res => setRows(res))
                .catch(err => handleSnackBar(getGetErrorText(err), "error"));
                
        }
    }

    const resetStates = () => {
        setSelected([]);
        setSelectedPeople([]);
        setVisibleDatePicker(false);
        setLimitedTimeChecked(false);
    }

    const handleDateStartChange = (date: Date | null) => {
        setSelectedDateStart(date);
    };

    const handleDateEndChange = (date: Date | null) => {
        setSelectedDateEnd(date);
    };

    const handleAddClick = () => {
        const devicesSentData: DeviceOnlyIdRequest[] = deviceGroupsChecked ? [] : selected.map(uuid => ({ uuid }))
        const deviceGroupsSentData: DeviceOnlyIdRequest[] = deviceGroupsChecked ? selected.map(uuid => ({ uuid })) : []

        let dept: AccessPermissionCreateRequest = {
            employees: selectedPeople.map(employeeUuid => ({
                employeeUuid,
                devices: devicesSentData,
                deviceGroups: deviceGroupsSentData,
            })),
            accessStartDate: selectedDateStart?? new Date()
        }

        if (limitedTimeChecked) dept.accessEndDate = selectedDateEnd?? new Date();

        accessPermissionsService.createAccessPermission(dept, sessionContext.token)
            .then(res => {
                handleSnackBar(ResponseSuccessMessages.ADD, 'success')
                fetchEmployeesWithAccess();
                resetStates();
            })
            .catch(err => handleSnackBar(getCreateErrorText(err), "error"));
    };

    const handleRemoveClick = (optionalDept?: AccessPermissionDeleteRequest, type?: PermissionChipType) => {
        const devicesSentData: DeviceOnlyIdRequest[] = deviceGroupsChecked ? [] : selected.map(uuid => ({ uuid }))
        const deviceGroupsSentData: DeviceOnlyIdRequest[] = deviceGroupsChecked ? selected.map(uuid => ({ uuid })) : []
        
        const dept: AccessPermissionDeleteRequest = {
            employees: selectedPeople.map(employeeUuid => ({
                employeeUuid,
                devices: devicesSentData,
                deviceGroups: deviceGroupsSentData,
            }))
        }

        accessPermissionsService.deleteAccessPermission(optionalDept ?? dept, sessionContext.token)
            .then(res => {
                handleSnackBar(ResponseSuccessMessages.DELETE, 'success')
                fetchEmployeesWithAccess();
                setSelected([]);
                setSelectedPeople([]);
            })
            .catch(err => handleSnackBar(getDeleteErrorText(err), "error"));
    };

    const handleClose = () => {
        setVisibleDatePicker(false);
        setLimitedTimeChecked(false);
    }

    const handleChangeDeviceGroupsCheck = (value: boolean) => {
        setDeviceGroupsChecked(value);
        setSelected([]);
    }

    return (
        <div style={{ width: '100%' }}>
            <CssBaseline />

            <Grid container sx={{
                height: {
                    md: 'calc(100vh - 200px)'
                }
            }}>
                <Grid component={Paper} container item xs={smallScreen ? 12 : 8} sx={{
                    width: '100%',
                    borderRight: {
                        md: "1px solid #dddddd"
                    },
                }}>
                    <PeoplesTable
                        rows={peopleRows}
                        selected={selectedPeople}
                        setSelected={setSelectedPeople}
                        handleRemoveClick={handleRemoveClick}
                        setLocationId={setLocationId}
                        setDepartmentId={setDepartmentId}
                    />
                </Grid>
                <Grid component={Paper} container item xs={smallScreen ? 12 : 4} justifyContent='space-around' alignContent='flex-start'>
                    <ManageAccessDeviceTable
                        rows={rows}
                        fetchRows={fetchRows}
                        selected={selected}
                        setSelected={setSelected}
                        deviceGroupsChecked={deviceGroupsChecked}
                        handleChangeDeviceGroupsCheck={handleChangeDeviceGroupsCheck} />
                </Grid>
                {selected.length > 0 && selectedPeople.length > 0 && <Grid item xs={smallScreen ? 12 : 4} sx={{
                    height: {
                        xs: 130,
                        lg: "initial"
                    },
                    display: {
                        md: "none",
                    },
                }}></Grid>}
            </Grid>

            <Box
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                position="fixed"
                sx={(theme) => ({
                    right: {
                        xs: 20,
                        sm: 50,
                    },
                    bottom: {
                        xs: 20,
                        sm: 30,
                    },
                    zIndex: theme.zIndex.mobileStepper,
                })}>
                <Grow in={visibleDatePicker} unmountOnExit>
                    <Paper variant='outlined' sx={{
                        width: {
                            xs: "70vw",
                            sm: 450,
                        },
                        zIndex: 2,
                        mb: 1,
                    }}>
                        <Grid container item xs={12} justifyContent="center" alignItems="center" style={{ marginTop: 16 }}>
                            <Grid item sx={{
                                width: '100%',
                                mt: 1,
                                mr: 3,
                            }}>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                        control={<Switch {...bindLimitedTimeChecked} />}
                                        label={t("Limited time permission")}
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12}>
                                <DateTimePicker
                                    onChange={handleDateStartChange}
                                    value={selectedDateStart}
                                    minDate={new Date()}
                                    format='MM/dd/yyyy HH:mm'
                                    label={t("Permission starts on")}
                                    ampm={false}
                                    sx={{ width: "100%", mt: 3 }}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                />
                            </Grid>
                            { limitedTimeChecked && (
                                <Grid item xs={12}>
                                    <DateTimePicker
                                        disabled={!limitedTimeChecked}
                                        onChange={handleDateEndChange}
                                        value={selectedDateEnd}
                                        minDate={new Date()}
                                        format='MM/dd/yyyy HH:mm'
                                        label={t("Permission ends on")}
                                        ampm={false}
                                        sx={{ width: "100%", mt: 3 }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container item xs={12} spacing={2} justifyContent="center">
                            <Grid item>
                                <Button variant='contained' color='success' sx={{ m: 1 }} onClick={handleAddClick}>
                                    {t('Save')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' sx={{ m: 1 }} onClick={handleClose}>
                                    {t('Cancel')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
                <Box>
                    {fabs.map(fab => (
                        <Zoom
                            in={selected.length > 0 && selectedPeople.length > 0}
                            timeout={transitionDuration}
                            style={{
                                transitionDelay: `${(selected.length > 0 && selectedPeople.length > 0) ? transitionDuration.exit : 0}ms`,
                            }}
                            unmountOnExit
                            key={fab.label}
                        >
                            <Fab
                                variant='extended'
                                color={fab.color}
                                sx={{ m: 1 }}
                                onClick={fab.onClick}
                                disabled={!fab.labelAlwaysVisible && visibleDatePicker}
                            >
                                {fab.icon}
                                <Hidden smDown={!fab.labelAlwaysVisible}>
                                    {t(fab.label)}
                                </Hidden>
                            </Fab>
                        </Zoom>
                    ))}
                </Box>
                <Backdrop open={visibleDatePicker} onClick={handleClose} />
            </Box>
        </div>
    );
}