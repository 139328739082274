import { genericDelete, genericGet, genericPost } from "../genericService";
import { ApiHttpErrorType } from "../types";
import { deleteAccessPermissionsEndpoint, getAccessPermissionsEndpoint } from "./endpoints/accessPermissions";
import { AccessPermissionCreateRequest, AccessPermissionCreateResponse, AccessPermissionDeleteRequest, AccessPermissionGetAllResponse } from "./types";

export interface PermissionData {
  uuid: string;
  name: string;
  type: "group" | "device";
  color?: string;
};

export interface AccessPermissionTable {
  uuid: string;
  name: string;
  permissions: PermissionData[];
};

const getAll = async (token?: string): Promise<AccessPermissionGetAllResponse[]> => {
  return genericGet<AccessPermissionGetAllResponse[]>(getAccessPermissionsEndpoint(), {}, token);
}

const createAccessPermission = async (dept: AccessPermissionCreateRequest, token?: string): Promise<AccessPermissionCreateResponse[]> => {
  return genericPost<AccessPermissionCreateResponse[]>(getAccessPermissionsEndpoint(), dept, token);
}

const deleteAccessPermission = async (dept: AccessPermissionDeleteRequest, token?: string): Promise<{}> => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    },
    body: JSON.stringify(dept)
  };

  return fetch(deleteAccessPermissionsEndpoint(), requestOptions)
    .then(() => ({}))
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
}

const getAllToTable = async (locationId: string, departmentId: string, token?: string): Promise<AccessPermissionTable[]> => {
  const loc = locationId === '0' ? '' : locationId;
  const dep = departmentId === '0' ? '' : departmentId;
  
  return getAll(token)
    .then(res => res.filter(row => {
      if (!loc.length && !dep.length) return true;
      if (!loc.length && dep.length) return row.departments.some(d => d.uuid === departmentId);
      if (loc.length && !dep.length) return row.locations.some(l => l.uuid === locationId);

      return (
        row.locations.some(l => l.uuid === locationId)
        && row.departments.some(d => d.uuid === departmentId)
      )
    }))
    .then(res => res.map((p): AccessPermissionTable => {
      const { uuid, firstName, lastName, devices, deviceGroups } = p;
      const permissions: PermissionData[] = [];

      devices.forEach(({ uuid, name, location }) => {
        permissions.push({
          uuid, name,
          type: 'device',
          color: location?.color,
        })
      })

      deviceGroups.forEach(({ uuid, name }) => {
        permissions.push({
          uuid, name,
          type: 'group',
          color: "000000",
        })
      })

      return {
        uuid, permissions,
        name: `${firstName} ${lastName}`
      }
    }))
}

export const accessPermissionsService = {
    getAll,
    createAccessPermission,
    deleteAccessPermission,
    getAllToTable,
}
