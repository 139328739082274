import { Card, CardActionArea, CardContent, Container, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink} from 'react-router-dom';
import Restricted from 'components/Restricted';
import { PermissionCategory } from 'services/account-management';
import { useTranslation } from 'react-i18next';
import LazyLoadIcon from 'components/LazyLoadIcon';

const cardIconStyle = {
    fontSize: "inherit",
    color: "white",
};

const availableIcons = [
    "Settings",
    "FreeBreakfast",
    "Warning",
    "People",
    "ExitToApp",
    "Backup",
    "ManageAccountsIcon",
] as const;

export default function SettingsView() {
    const [t] = useTranslation();

    const cardData: { text: string, link: string, icon: typeof availableIcons[number] }[] = [
        // {
        //     text: "System",
        //     link: "system",
        //     icon: "Settings"
        // },
        {
            text: "My guests",
            link: "guests",
            icon: "FreeBreakfast"
        },
        // {
        //     text: "Alarms",
        //     link: "alarms",
        //     icon: "Warning"
        // },
        {
            text: "Work time registering",
            link: "work-time",
            icon: "People"
        },
        {
            text: "Evacuation",
            link: "evacuation",
            icon: "ExitToApp"
        },
        {
            text: "Manage access",
            link: "manage-access",
            icon: "ManageAccountsIcon"
        },
        // {
        //     text: "Import",
        //     link: "import",
        //     icon: "Backup"
        // },
    ];

    return (
        <Restricted to={PermissionCategory.Settings}>
            <Container sx={{ pt: 2, pb: 4 }}>
                <Grid
                    container
                    spacing={4}
                    alignContent="flex-start"
                    wrap="wrap"
                    justifyContent='flex-start'
                    direction='row'
                >
                    {cardData.map((card,index) => (
                        <Grid key={index} item>
                            <Link component={RouterLink} to={`/settings/${card.link}`}>
                                <Card sx={{
                                    backgroundColor: "primary.main",
                                    width: "20vw",
                                    height: "20vw",
                                    maxWidth: 200,
                                    maxHeight: 200,
                                    minWidth: 150,
                                    minHeight: 150,
                                }}>
                                    <CardActionArea style={{ height: "100%" }}>
                                        <CardContent>
                                            <Grid container
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                spacing={2}>
                                                <Grid container item alignItems="center" justifyContent="center">
                                                    <Grid item>
                                                        <Typography gutterBottom variant="h5" style={{ color: "white" }}>
                                                            {t(card.text)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sx={{
                                                    fontSize: {
                                                        xs: 50,
                                                        md: 70,
                                                    },
                                                }}>
                                                    <LazyLoadIcon iconName={card.icon} style={cardIconStyle} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Restricted>
    );
}