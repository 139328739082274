import { apiUrl } from "../../config";

const getDeviceGroupsEndpoint = () => `${apiUrl}/device-groups`;
const getDeviceGroupAvailableDevicesEndpoint = (deviceGroupId?: string) => `${apiUrl}/device-groups/get-available-devices${deviceGroupId ? `?deviceGroupUuid=${deviceGroupId}` : ""}`;
const getDeviceGroupEndpoint = (deviceGroupId: string) => `${apiUrl}/device-groups/${deviceGroupId}`;
const createDeviceGroupEndpoint = () => `${apiUrl}/device-groups`;
const updateDeviceGroupEndpoint = (deviceGroupId: string) => `${apiUrl}/device-groups/${deviceGroupId}`;
const deleteDeviceGroupEndpoint = (deviceGroupId: string) => `${apiUrl}/device-group/${deviceGroupId}`;

export {
  getDeviceGroupsEndpoint,
  getDeviceGroupAvailableDevicesEndpoint,
  getDeviceGroupEndpoint,
  createDeviceGroupEndpoint,
  updateDeviceGroupEndpoint,
  deleteDeviceGroupEndpoint,
};
