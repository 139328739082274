import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Collapse, Grid, IconButton, Paper } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import TableSearchbar from 'components/TableSearchbar';
import { AccessPermissionTable } from 'services/device-management/accessPermissions.service';
import { AccessPermissionDeleteRequest } from 'services/device-management/types';
import { useSelectInput } from 'hooks/input-hook';
import LocationSelect from 'components/LocationSelect';

export type PermissionChipType = "group" | "device" | "more";

interface PermissionChipProps {
    uuid?: string;
    employeeUuid?: string;
    name: string;
    color?: string;
    type: PermissionChipType;
    handleRemoveClick?: (optionalDept?: AccessPermissionDeleteRequest, type?: PermissionChipType) => void;
}

function PermissionChip(props: PermissionChipProps) {
    const { uuid, name, type, color, employeeUuid, handleRemoveClick } = props;
    const { t } = useTranslation();
    
    const handleDelete = () => {
        if (!handleRemoveClick || !uuid || !employeeUuid) return;

        const dept: AccessPermissionDeleteRequest = {
            employees: [
                {
                    employeeUuid,
                    devices: type === 'device' ? [{ uuid }] : [],
                    deviceGroups: type === 'group' ? [{ uuid }] : [],
                }
            ]
        }

        handleRemoveClick(dept, type);
    };

    function ChipStyleProps(color: undefined | string) {
        return { border: `2px solid ${color}` };
    }

    const getNamePrefix = () => {
        if (type === 'device') return t("Controller") + ": ";
        if (type === 'group') return t("Device group") + ": ";
        return "";
    }

    return (
        <Chip
            sx={{
                m: 0.5,
                maxWidth: {
                    xs: 150,
                    sm: 250,
                },
            }}
            label={`${getNamePrefix()}${name}`}
            onDelete={handleRemoveClick && handleDelete}
            style={ChipStyleProps(`#${color}`)}
        />
    )
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: "name";
    label: string;
    numeric: boolean;
    alignCenter: boolean;
}

const headCells: HeadCell[] = [
    { id: 'name', numeric: false, label: 'First and last name', alignCenter: false },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: "name") => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    switchOpenPermissions: () => void;
    openPermissions: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, switchOpenPermissions, openPermissions } = props;
    const createSortHandler = (property: "name") => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const [t] = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all people' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sx={{ width: 150 }}
                        align={headCell.alignCenter ? "center" : "left"}
                        padding="none"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden as any}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell
                    align="justify"
                    sx={{ width: 'auto', minWidth: 160 }}
                >
                    {t("Permissions")}
                </TableCell>
                <TableCell
                    align="right"
                    sx={{ minWidth: 125, padding: 2 }}
                >
                    <IconButton aria-label="expand row" size="small" onClick={switchOpenPermissions}>
                        {openPermissions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

interface RowProps {
    row: AccessPermissionTable;
    isItemSelected: boolean;
    labelId: string;
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
    handleRemoveClick: (optionalDept?: AccessPermissionDeleteRequest, type?: PermissionChipType) => void;
    locationId: string;
    departmentId: string;
    open: boolean;
    setOpen: (value: boolean) => void;
}

function Row(props: RowProps) {
    const { row, isItemSelected, labelId, handleClick, handleRemoveClick, locationId, departmentId, open, setOpen } = props;
    const [t] = useTranslation();
    let timeoutResize: NodeJS.Timeout;
    const [ chipContainerBox, setChipContainerBox ] = React.useState<HTMLDivElement|null>(null);
    const [ notSizedChips, setNotSizedChips ] = React.useState<number>(0);

    React.useEffect(() => {
        setOpen(false);
    }, [locationId, departmentId]);

    React.useEffect(() => {
        changeOpenMoreChips();
    }, [row]);

    React.useEffect(() => {
        changeOpenMoreChips()
        
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [chipContainerBox]);

    const resize = () => {
        if (timeoutResize) clearTimeout(timeoutResize);
        timeoutResize = setTimeout(changeOpenMoreChips, 100);
    }

    const onRefChange = React.useCallback((el: HTMLDivElement | null) => {
        setChipContainerBox(el);
    }, [])

    const changeOpenMoreChips = () => {
        if (!chipContainerBox) return;

        let tempNotSizedChips = 0;

        chipContainerBox.childNodes.forEach((chip: any) => {
            if (!chip) return;

            if (chip.offsetTop > 20) {
                tempNotSizedChips++;
            }
            
        });
        setNotSizedChips(tempNotSizedChips)
    }

    const cellToTopStyles = () => row.permissions.length > 0 ? ({ verticalAlign: 'top', pt: 2 }) : ({})

    return (
        <React.Fragment>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.uuid}
                selected={isItemSelected}
            >
                <TableCell
                    padding="checkbox"
                    onClick={(event) => handleClick(event, row.uuid)}
                    sx={cellToTopStyles()}
                >
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell
                    padding="none"
                    id={labelId}
                    align="left"
                    onClick={(event) => handleClick(event, row.uuid)}
                    sx={cellToTopStyles()}
                >
                    <Box style={{ margin: '12px 0' }}>{row.name}</Box>
                </TableCell>
                <TableCell align="left" sx={{ width: 'auto', minWidth: 160, px: 0 }}>
                    {row.permissions.filter(permission => permission.name.length > 0).length === 0 ? (
                        t("No permissions")
                    ) : (
                        <Grid container alignItems="flex-start" sx={{ position: 'relative' }}>
                            <Grid item xs sx={{ overflow: "hidden" }}>
                                <Collapse in={open} timeout="auto" collapsedSize={40}>
                                    <Grid ref={onRefChange}>
                                        {row.permissions
                                            .filter(permission => permission.name.length > 0)
                                            .map(permission => (
                                                <PermissionChip
                                                    key={permission.uuid}
                                                    uuid={permission.uuid}
                                                    employeeUuid={row.uuid}
                                                    name={permission.name}
                                                    type={permission.type}
                                                    handleRemoveClick={handleRemoveClick}
                                                    color={permission.color}
                                                />
                                            ))
                                        }
                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                    )}
                </TableCell>
                <TableCell sx={cellToTopStyles()}>
                    { notSizedChips > 0 &&
                        <Grid container wrap='nowrap' justifyContent='flex-end'>
                            <Grid item style={{ rotate: '180deg' }}>
                                <Collapse in={!open} timeout="auto" style={{ rotate: '180deg' }} >
                                    <PermissionChip
                                        name={`+${notSizedChips}`}
                                        color={"000000"}
                                        type='more'
                                    />
                                </Collapse>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} sx={{ m: '5px 0' }}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

interface PeoplesTableProps {
    rows: AccessPermissionTable[];
    selected: string[];
    setSelected: (list: string[]) => void;
    handleRemoveClick: (optionalDept?: AccessPermissionDeleteRequest, type?: PermissionChipType) => void;
    setDepartmentId: (departmentId: string) => void;
    setLocationId: (locationId: string) => void;
}

export default function PeoplesTable(props: PeoplesTableProps) {
    const { rows, selected, setSelected, handleRemoveClick, setDepartmentId, setLocationId } = props;
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<'name'>('name');
    const [t] = useTranslation();
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [ openPermissions, setOpenPermissions ] = React.useState<boolean[]>([]);
    const { value: location, setValue: setLocation, bind: bindLocation } = useSelectInput("");
    const { value: department, setValue: setDepartment, bind: bindDepartment } = useSelectInput("");

    React.useEffect(() => {
        if (openPermissions.length !== rows.length) {
            setOpenPermissions(Array(rows.length).fill(false))
        }
    }, [rows]);

    useEffect(() => setDepartmentId(department), [department]);
    useEffect(() => setLocationId(location), [location]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: "name") => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.uuid);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (uuid: string) => selected.includes(uuid);

    const containsSearchTerm = (person: AccessPermissionTable, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        if (term === "") return true;
        return (
            person.name.toLowerCase().includes(term)
            || !!person.permissions.filter(permission => permission.name.toLowerCase().includes(term)).length
        );
    };

    const switchOpenPermissions = (value?: boolean, index?: number) => {
        const openPermissionsCopy = openPermissions.slice();
        
        if (index !== undefined) openPermissionsCopy[index] = !!value;
        else if (openPermissions.includes(true)) openPermissionsCopy.fill(false);
        else openPermissionsCopy.fill(true)

        setOpenPermissions(openPermissionsCopy)
    }

    return (
        <Paper sx={{ width: '100%', height: '100%' }}>
            <Toolbar component={Grid} container spacing={2} sx={{ pt: 1 }}>
                <Grid item xs={6} lg>
                    <Typography variant="h6" id="tableTitle">
                        {t("PEOPLES LIST")}
                    </Typography>
                </Grid>

                <Grid item xs={6} lg={3} sx={{ order: { xs: 2, lg: 0 } }}>
                    <LocationSelect
                        setValue={setLocation}
                        {...bindLocation}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={6} lg={3} sx={{ order: { xs: 2, lg: 0 } }}>
                    <LocationSelect
                        type='department'
                        setValue={setDepartment}
                        {...bindDepartment}
                        fullWidth
                    />
                </Grid>
                
                <Grid item xs={6} lg={3}>
                    <TableSearchbar type="people" searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                </Grid>
            </Toolbar>
            <TableContainer sx={{ maxHeight: `calc(100vh - 200px - 64px)`, minHeight: 110 }}>
                <Table
                    aria-labelledby="tableTitle"
                    aria-label="peoples table"
                    stickyHeader
                    sx={{ minWidth: 420 }}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        switchOpenPermissions={switchOpenPermissions}
                        openPermissions={openPermissions.includes(true)}
                    />
                    <TableBody>
                        {stableSort(rows.filter(row => containsSearchTerm(row, searchTerm)), getComparator(order, orderBy))
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.uuid);
                                const labelId = `people-table-checkbox-${index}`;

                                return (
                                    <Row
                                        key={row.uuid}
                                        row={row}
                                        isItemSelected={isItemSelected}
                                        labelId={labelId}
                                        handleRemoveClick={handleRemoveClick}
                                        handleClick={handleClick}
                                        locationId={location}
                                        departmentId={department}
                                        setOpen={(value) => switchOpenPermissions(value, index)}
                                        open={openPermissions[index]}
                                    />
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
