import React, { FormEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { VariantType, useSnackbar } from 'notistack';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

import { useInput, useMultiselectInput } from 'hooks/input-hook';
import { useSessionContext } from 'contexts/SessionContext';
import { DeviceGroupAvailableDevicesGetResponse, DeviceGroupCreateRequest, DeviceGroupUpdateRequest } from 'services/device-management/types';
import { getCreateErrorText, getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import { deviceGroupService } from 'services/device-management/deviceGroup.service';

interface AddManageAccessSettingsFormProps {
    editedDeviceGroup?: string;
    handleClose: () => void;
};

export default function AddManageAccessSettingsForm(props: AddManageAccessSettingsFormProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [ sessionContext ] = useSessionContext();
    const { editedDeviceGroup, handleClose } = props;

    const { value: name, setValue: setName, bind: bindName } = useInput("");
    const { value: devicesSelected, setValue: setDevicesSelected, bind: bindDevicesSelected } = useMultiselectInput([]);

    const [ devices, setDevices ] = React.useState<DeviceGroupAvailableDevicesGetResponse>([]);

    const [ nameError, setNameError ] = React.useState<boolean>(false);
    const [ devicesError, setDevicesError ] = React.useState<boolean>(false);

    const [t] = useTranslation();

    useEffect(() => {
        refreshData();
    }, [editedDeviceGroup]);

    const refreshData = () => {
        deviceGroupService.getAvailableDevices(editedDeviceGroup, sessionContext.token)
            .then(setDevices)
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
        
        if (editedDeviceGroup) deviceGroupService.getOne(editedDeviceGroup, sessionContext.token)
            .then(res => {
                if (!res) return;

                setName(res.name)
                setDevicesSelected(res.devices.map(device => device.uuid))
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }

    const validationOk = (): boolean => {
        setNameError(false);
        setDevicesError(false);
        let isOk = true;

        if (!name.length) {
            isOk = false;
            setNameError(true);
        }

        if (!devicesSelected.length) {
            isOk = false;
            setDevicesError(true);
        }

        if (!isOk) handleSnackBar('You have to fill in all spaces', "error");
        
        return isOk;
    }

    const handleOnSubmit = async(e: FormEvent): Promise<void> => {
        e.preventDefault();
        if (!validationOk()) return;

        const dept: DeviceGroupCreateRequest | DeviceGroupUpdateRequest = {
            devices: devicesSelected.map(uuid => ({ uuid })),
            name,
        };

        if (editedDeviceGroup) {
            deviceGroupService.updateDeviceGroup(editedDeviceGroup, dept, sessionContext.token)
                .then(res => {
                    handleSnackBar(ResponseSuccessMessages.EDIT, 'success')
                    handleClose();
                })
                .catch(err => handleSnackBar(getCreateErrorText(err), 'error'));
        }
        else {
            deviceGroupService.createDeviceGroup(dept, sessionContext.token)
                .then(res => {
                    handleSnackBar(ResponseSuccessMessages.ADD, 'success')
                    handleClose();
                })
                .catch(err => handleSnackBar(getCreateErrorText(err), 'error'));
        }
    }

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">
                    {t("Insert device group")}
                </Typography>
                <Box component="form" sx={{ width: "100%", mt: 1 }} onSubmit={handleOnSubmit}>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        fullWidth
                        id="deviceGroupName"
                        label={t("Device group name")}
                        name="deviceGroupName"
                        autoComplete="deviceGroupName"
                        autoFocus
                        error={nameError}
                        {...bindName}
                    />
                    <FormControl required fullWidth sx={{ width: "100%" }} variant='standard' error={devicesError}>
                        <InputLabel id="device-select-label">{t("Choose device")}</InputLabel>
                        <Select
                            fullWidth
                            multiple
                            labelId="device-select-label"
                            id="device-select"
                            sx={{ width: '100%' }}
                            {...bindDevicesSelected}
                        >
                            {devices.map(d => (
                                <MenuItem key={d.uuid} value={d.uuid}>
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedDeviceGroup ? "Save" : "Add device group")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}