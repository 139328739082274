import { genericDelete, genericGet, genericPost, genericPut } from "../genericService";
import { createDeviceGroupEndpoint, deleteDeviceGroupEndpoint, getDeviceGroupAvailableDevicesEndpoint, getDeviceGroupEndpoint, getDeviceGroupsEndpoint, updateDeviceGroupEndpoint } from "./endpoints";
import { DeviceGroupAvailableDevicesGetResponse, DeviceGroupCreateRequest, DeviceGroupCreateResponse, DeviceGroupGetAllResponse, DeviceGroupGetAllToTable, DeviceGroupGetResponse, DeviceGroupUpdateRequest, DeviceGroupUpdateResponse } from "./types";

export interface DeviceGroupData {
  id: string;
  color: string;
  name: string;
  description: string;
  controllers: string;
  status: string;
};

const getAll = async (token?: string): Promise<DeviceGroupGetAllResponse[]> => {
  return genericGet<DeviceGroupGetAllResponse[]>(getDeviceGroupsEndpoint(), {}, token);
}

const getOne = async (uuid: string, token?: string): Promise<DeviceGroupGetResponse | undefined> => {
  return genericGet<DeviceGroupGetResponse>(getDeviceGroupEndpoint(uuid), {}, token);
}

const getAvailableDevices = async (uuid?: string, token?: string): Promise<DeviceGroupAvailableDevicesGetResponse> => {
  return genericGet<DeviceGroupAvailableDevicesGetResponse>(getDeviceGroupAvailableDevicesEndpoint(uuid), {}, token);
}

const deleteDeviceGroup = (uuid: string, token?: string): Promise<{status: number, statusText: string}> => {
  return genericDelete(deleteDeviceGroupEndpoint(uuid), token);
};

const createDeviceGroup = (deviceGroup: DeviceGroupCreateRequest, token?: string): Promise<DeviceGroupCreateResponse> => {
  return genericPost<DeviceGroupCreateResponse>(createDeviceGroupEndpoint(), deviceGroup, token);
};

const updateDeviceGroup = (uuid: string, deviceGroup: DeviceGroupUpdateRequest, token?: string): Promise<DeviceGroupUpdateResponse> => {
  return genericPut<DeviceGroupUpdateResponse>(updateDeviceGroupEndpoint(uuid), deviceGroup, token);
};

const getAllToTable = (token?: string): Promise<DeviceGroupGetAllToTable[]> => {
  return getAll(token).then(res => res.map(({ uuid, name, devices }) => ({ uuid, name, devicesTable: devices.map(d => d.name).join(", ") })))
}

export const deviceGroupService = {
  getAll,
  getOne,
  getAvailableDevices,
  deleteDeviceGroup,
  createDeviceGroup,
  updateDeviceGroup,
  getAllToTable,
};
