import { Box, Button, Grid } from '@mui/material';
import { useSessionContext } from 'contexts/SessionContext';
import { useSnackbar, VariantType } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getGetErrorText, ResponseSuccessMessages } from 'services/genericService';
import ManageAccessSettingsTable from './ManageAccessSettingsTable';
import DeleteDialog from 'components/DeleteDialog';
import DialogOverlay from 'components/DialogOverlay';
import { deviceGroupService } from 'services/device-management/deviceGroup.service';
import AddManageAccessSettingsForm from './AddManageAccessSettingsForm';
import { DeviceGroupGetAllToTable } from 'services/device-management/types';

export default function ManageAccessSettings() {
    const [sessionContext] = useSessionContext();
    const [t] = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ rows, setRows ] = React.useState<DeviceGroupGetAllToTable[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [editedRow, setEditedRow] = React.useState<string>("");
    
    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(t(message), { variant });
    };

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        deviceGroupService.getAllToTable(sessionContext.token)
            .then(res => setRows(res))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    }
    
    const containsSearchTerm = (deviceGroup: DeviceGroupGetAllToTable, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();
        return deviceGroup.name.toLowerCase().includes(term);
    };
    
    const handleClickEditOpen = (selected: string) => {
        setEditedRow(selected);
        setEditOpen(true);
    };

    const handleClickDeleteOpen = (selected: string) => {
        setEditedRow(selected);
        setDeleteOpen(true);
    };

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setDeleteOpen(false);
        refreshData();
    };

    const handleDelete = () => {
        deviceGroupService.deleteDeviceGroup(editedRow, sessionContext.token)
            .then(() => {
                handleClose();
                handleSnackBar(ResponseSuccessMessages.DELETE, "success");
            })
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
    };
    
    return (
        <>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid item container alignItems="center" xs>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add device group")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            
            <Box mt={2}>
                <ManageAccessSettingsTable
                    rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                    handleClickEditOpen={handleClickEditOpen}
                    handleClickDeleteOpen={handleClickDeleteOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Box>

            <DialogOverlay isOpen={addOpen} handleClose={handleClose} title={t("Add device group")}>
                <AddManageAccessSettingsForm
                    handleClose={handleClose}
                />
            </DialogOverlay>

            <DialogOverlay isOpen={editOpen} handleClose={handleClose} title={t("Edit device group")}>
                <AddManageAccessSettingsForm
                    editedDeviceGroup={editedRow}
                    handleClose={handleClose}
                />
            </DialogOverlay>

            <DialogOverlay isOpen={deleteOpen} handleClose={handleClose} color='error' title={t("Delete device groups")}>
                <DeleteDialog
                    type="device groups"
                    handleDelete={handleDelete}
                    names={rows.filter(row => row.uuid === editedRow).map(row => row.name)}
                    handleClose={handleClose}
                />
            </DialogOverlay>
        </>
    );
}
